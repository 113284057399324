import React from "react";
import { getLayout } from "../layouts/DefaultPageLayout";
import ServersidePageUtility from "../libs/ServersidePageUtility";
import Link from "next/link";
import FeatureTeaser from "../modules/user/components/global/FeatureTeaser";
import Button from "../components/Button";
import EmployerReferences from "../modules/jobs/components/employer/EmployerReferences/EmployerReferences";
import CeDataGetter from "../modules/cms_content/libs/CeDataGetter";
import getConfig from "next/config";
import useWindowSize from "../hooks/useWindowSize";
import ImageWithSlogan from "../modules/cms_content/components/global/ImageWithSlogan";

const EmployerReferences_object = {
  id: "33513",
  CType: "mask_employer_references",
  properties: {
    frameClass: "text-width",
    showInOnPageNavigation: "0",
  },
  headerParts: {
    subheader: {
      subheader: {
        title: "Folgende Kunden nutzen den LTO-Stellenmarkt (Auszug)",
      },
    },
  },
  contentParts: {
    employers: [
      {
        uid: "5",
        name: "Allen & Overy LLP",
        logo: "977387",
        logoMasked: "922963",
        pathSegment: "allen-overy-llp",
      },
      {
        uid: "49",
        name: "Ashurst",
        logo: "977394",
        logoMasked: "922967",
        pathSegment: "ashurst",
      },
      {
        uid: "2",
        name: "Bird & Bird LLP",
        logo: "43722",
        logoMasked: "922975",
        pathSegment: "bird-bird-llp",
      },
      {
        uid: "163",
        name: "Bryan Cave Leighton Paisner",
        logo: "977400",
        logoMasked: "922984",
        pathSegment: "bryan-cave-leighton-paisner",
      },
      {
        uid: "66",
        name: "CBH Rechtsanwälte",
        logo: "977409",
        logoMasked: "923040",
        pathSegment: "cbh-rechtsanwaelte",
      },
      {
        uid: "10",
        name: "Gleiss Lutz",
        logo: "904003",
        logoMasked: "923022",
        pathSegment: "gleiss-lutz",
      },
      {
        uid: "35",
        name: "DLA Piper UK LLP",
        logo: "978723",
        logoMasked: "923032",
        pathSegment: "dla-piper-uk-llp",
      },
      {
        uid: "6",
        name: "Görg",
        logo: "976440",
        logoMasked: "922410",
        pathSegment: "goerg",
      },
      {
        uid: "69",
        name: "GvW Graf von Westphalen",
        logo: "977430",
        logoMasked: "923015",
        pathSegment: "gvw-graf-von-westphalen",
      },
      {
        uid: "16",
        name: "GSK STOCKMANN",
        logo: "974391",
        logoMasked: "923011",
        pathSegment: "gsk-stockmann",
      },
      {
        uid: "15",
        name: "Linklaters",
        logo: "977459",
        logoMasked: "973552",
        pathSegment: "linklaters",
      },
      {
        uid: "26",
        name: "Luther Rechtsanwaltsgesellschaft mbH",
        logo: "977461",
        logoMasked: "973554",
        pathSegment: "luther-rechtsanwaltsgesellschaft-mbh",
      },
      {
        uid: "25",
        name: "Mayer Brown LLP",
        logo: "131547",
        logoMasked: "974412",
        pathSegment: "mayer-brown-llp",
      },
      {
        uid: "74",
        name: "Oppenhoff",
        logo: "977471",
        logoMasked: "973504",
        pathSegment: "oppenhoff",
      },
      {
        uid: "13",
        name: "Osborne Clarke Rechtsanwälte Steuerberater Partnerschaft mbB",
        logo: "221549",
        logoMasked: "974413",
        pathSegment: "osborne-clarke",
      },
    ],
    logoVariant: "",
  },
};

const Features = () => {
  const windowSize = useWindowSize();
  const { publicRuntimeConfig } = getConfig();
  const jobSearchLink = `${publicRuntimeConfig.defaultSearchPath}?startTour=1`;
  return (
    <div className="ce-content">
      <div className="grid-x">
        <div className="cell large-8 large-offset-2">
          <h1 className="ce--space-after-extra-small">
            Dein persönliches Profil auf LTO-Karriere
          </h1>
          <ImageWithSlogan
            slogan={`Weil Jobsuche<br/><b>Freude</b> machen kann`}
            ratio={windowSize.width < 768 ? "" : "wide"}
            imageSrc={
              windowSize.width < 992
                ? "https://backend.lto-karriere.de/fileadmin/files/allgemein/Headergrafik-Landingpage-Cockpit_mobile.jpeg"
                : "https://backend.lto-karriere.de/fileadmin/files/allgemein/Headergrafik-Landingpage-Cockpit.jpeg"
            }
          />
          <p className="ce--space-before-extra-small">
            Weil Jobsuche Freude machen kann: Registriere Dich auf LTO-Karriere
            und sieh auf einen Blick in unserem Stellenmarkt, welche Jobs zu Dir
            passen. Du willst keinen neu eingestellten Job verpassen? Dann
            abonniere unsere Jobs per Mail. Und wenn Du dich auf eine spannende
            Stelle bewerben willst, geht das ebenfalls mit einem Klick, den wir
            generieren auf Wunsch die gesamte PDF-Bewerbung für Dich.
          </p>
          <div className="ce--space-before-small ce--space-after-extra-small">
            <h2 className="ce--space-after-extra-small">
              Jobs Alerts, Job Matching & One-Click Bewerbung
            </h2>
            <div className="grid-x grid-margin-x grid-margin-y">
              <div className="cell medium-6 large-4">
                <FeatureTeaser
                  title="Job Alerts"
                  description="Sag uns, welcher Schritt Dein nächster sein soll und wir schicken Dir automatisch passende Jobs per Mail. Die Mail-Frequenz bestimmst natürlich Du."
                  imageSrc="https://backend.lto-karriere.de/fileadmin/files/video_poster_images/PosterJobAlerts.jpg"
                  videoSrc="https://player.vimeo.com/progressive_redirect/playback/693114003/rendition/1080p?loc=external&signature=5f69bfb051a2c7b1a093183cabf841a77ae3c61c442e3e1afe5a38a1bb585c94"
                  disableButton
                  overlay
                />
              </div>
              <div className="cell medium-6 large-4">
                <FeatureTeaser
                  title="Job Matching"
                  description="Sieh im Stellenmarkt, wie gut ein Job zu Deinen Vorstellungen an Deine nächste Position passt - mit einem Blick. Wir zeigen Dir transparent, welche Parameter Dein zukünftiger Arbeitgeber erfüllt und welche (noch) nicht."
                  imageSrc="https://backend.lto-karriere.de/fileadmin/files/video_poster_images/posterJobMatching.jpg"
                  videoSrc="https://player.vimeo.com/progressive_redirect/playback/693122728/rendition/1080p?loc=external&signature=ce1a743aa3c4f533744083f4c91d502ce8c6c46d59b6a4bf447749e2b7c9ee5b"
                  disableButton
                  overlay
                />
              </div>
              <div className="cell medium-6 large-4">
                <FeatureTeaser
                  id="OneClick"
                  title="One-Click Bewerbung"
                  description="Spar Dir das mühsame Erstellen von PDF-Bewerbungen und Anschreiben - das übernehmen wir für Dich. Du willst eigene Leistungsnachweise oder ein Motivationsschreiben anhängen? Kein Problem."
                  imageSrc="https://backend.lto-karriere.de/fileadmin/files/video_poster_images/PosterOne-ClickBewerbung.jpg"
                  videoSrc="https://player.vimeo.com/progressive_redirect/playback/693114047/rendition/1080p?loc=external&signature=9488fbda37d42b4e1ea82d00d0dbbf508b4230f1b21529c69a2aaae84937e59e"
                  disableButton
                  overlay
                />
              </div>
            </div>
            <div className="ce--space-before-extra-small text-center">
              <Link href={jobSearchLink} passHref>
                <Button layout="primary" text="Zu den Features" />
              </Link>
            </div>
          </div>
          <div className="ce--space-before-small ce--space-after-extra-small">
            <h2 className="ce--space-after-extra-small text-center">
              Arbeitgeber auf LTO-Karriere (Auswahl)
            </h2>
            <EmployerReferences
              id={CeDataGetter.getUid(EmployerReferences_object)}
              employers={EmployerReferences_object.contentParts.employers}
              logoVariant={EmployerReferences_object.contentParts.logoVariant}
            />
            <div className="ce--space-before-extra-small text-center">
              <Link href={"/registrierung"} passHref>
                <Button layout="primary" text="Jetzt registrieren" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Get static props of page
 *
 * @param params
 * @param preview
 * @returns {Promise.<{props, contents: Array}>}
 */
export async function getStaticProps({ params, preview = false }) {
  const defaultPageProps = await ServersidePageUtility.defaultPageProps();

  return {
    props: {
      ...defaultPageProps,
    },
  };
}

Features.getLayout = getLayout;

export default Features;
