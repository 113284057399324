import React from "react";
import styles from "./ImageWithSlogan.module.scss";
import CropApiImage from "../CropApiImage";
import Image from "../../../../../components/Image";

const ImageWithSlogan = ({ slogan, image, imageSrc, ratio }) => {
  return (
    <div className={`${styles.wrapper} ce-ratio-box ce-ratio-box--${ratio}`}>
      <div className="ce-ratio-box__content ">
        {image && (
          <CropApiImage image={image} layout="fill" className="object-fit" />
        )}
        {imageSrc && (
          <Image
            src={imageSrc}
            nextOptimizedImage
            layout="fill"
            className={`object-fit`}
          />
        )}
        <h3
          className={styles.slogan}
          dangerouslySetInnerHTML={{ __html: slogan }}
        />
      </div>
    </div>
  );
};

export default ImageWithSlogan;
