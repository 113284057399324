import React, { useState, useEffect } from "react";

import { SwiperSlide } from "swiper/react";
import Link from "next/link";
import styles from "./EmployerReferences.module.scss";
import ApiImage from "../../../../cms_content/components/global/ApiImage";
import SwiperSlider from "../../../../cms_content/components/global/SwiperSlider/SwiperSlider";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import Icon from "../../../../../components/Icon";

const EmployerReferences = ({ id, employers, logoVariant }) => {
  const defaultLogoVariant = "logoMasked";
  const [slidesView, updateView] = useState(5);
  const [slidesColumn, updateColumn] = useState(5);
  const [slidesSpace, updateSpace] = useState(30);

  if (!logoVariant) {
    logoVariant = defaultLogoVariant;
  }

  useEffect(() => {
    if (window && window.innerWidth < 1024) {
      updateView(4);
      updateColumn(4);
      updateSpace(20);
    }
    if (window && window.innerWidth < 767) {
      updateView(3);
      updateColumn(4);
      updateSpace(15);
    }
  }, []);

  const pauseAutoplay = (pause) => {
    const swiperElement = document.querySelector(`#slider-${id}`);
    if (!swiperElement || !swiperElement.swiper) return;

    const swiperInstance = swiperElement.swiper;

    if (pause) {
      swiperInstance.autoplay.stop();
    } else {
      swiperInstance.autoplay.start();
    }
  };

  return (
    <>
      {employers && Array.isArray(employers) && employers.length > 0 && (
        <SwiperSlider
          id={`slider-${id}`}
          className={styles.reference__swiper}
          slidesPerView={slidesView}
          slidesPerColumn={slidesColumn}
          spaceBetween={slidesSpace}
          slidesPerColumnFill="row"
          autoplay={{ delay: 3000 }}
          navigation={{}}
          lazy
        >
          {employers &&
            employers.map((employer, index) => {
              const employerDetailLink = PagePathUtility.getPathConfig(
                "employerDetail",
                { ...employer }
              );

              const logo =
                employer[logoVariant || defaultLogoVariant] ?? employer.logo;
              const isMaskedLogo =
                logoVariant === "logoMasked" && employer[logoVariant];

              if (!logo) {
                return;
              }

              return (
                <SwiperSlide
                  className={`${styles.reference__SwiperSlide}`}
                  key={index}
                  onMouseEnter={() => pauseAutoplay(true)}
                >
                  <div
                    className={`${styles.reference__item} ${
                      employerDetailLink &&
                      styles.reference__item_profile_link_hover
                    }`}
                  >
                    <ApiImage
                      id={logo}
                      className={`${styles.reference__logo} ${
                        isMaskedLogo ? styles.reference__logomask : ""
                      }`}
                    />
                    {employerDetailLink && (
                      <div className={styles.reference__link_wrapper}>
                        <Icon name="arrow" size="small" />
                        <Link href={employerDetailLink}>
                          <a className={styles.reference__link}>
                            <span>Zum Profil</span>
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </SwiperSlider>
      )}
    </>
  );
};

export default EmployerReferences;
